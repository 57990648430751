// ============================
//     Common layout css start
// ============================

.pc-container {
    position: relative;
    top: $header-height;
    margin-left: $sidebar-width;
    min-height: calc(100vh - #{$header-height});

    .pcoded-content {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
    }

    .page-header + .row {
        padding-top: 50px;
    }
    .page-header + .pcoded-content {
        padding-top: calc(30px + 55px);
    }
}

.pcoded-content {
    &.container {
    max-width: 100%;
    @media (min-width: 1400px) {
        max-width: 1140px;
    }
    }
  }
  

.minimenu {
    .pc-container {
        margin-left: $sidebar-collapsed-width;
    }

    .page-header {
        left: $sidebar-collapsed-width;
    }
}

.page-header {
    background: var(--pc-breadcrumb-background);
    box-shadow: var(--pc-header-shadow);
    position: fixed;
    display: flex;
    align-items: center;
    top: $header-height;
    left: $sidebar-width;
    right: 0;
    z-index: 1023;
    min-height: 55px;
    padding: 13px 30px;

    .page-block {
        width: 100%;
    }

    .page-header-title {
        display: inline-block;
    }

    h5 {
        margin-bottom: 0;
        margin-right: 8px;
        padding-right: 8px;
        font-weight: 500;
        border-right: 1px solid darken($body-bg, 10%);
    }

    .breadcrumb {
        padding: 0;
        display: inline-flex;
        margin-bottom: 0;
        background: transparent;
        font-size: 13px;

        a {
            color: var(--bs-body-color);
        }

        .breadcrumb-item {
            a:hover {
                color: $primary;
            }

            + .breadcrumb-item::before {
                position: relative;
                top: 2px;
            }

            &:last-child {
                opacity: 0.75;
            }
        }

        svg {
            width: 14px;
            height: 14px;
            vertical-align: baseline;
        }
    }
}

.upgrade-card {
    .card-body {
        padding-bottom: 100px;
        z-index: 1;
        position: relative;
    }

    > .container {
        z-index: 5;
        position: relative;
        margin-top: -60px;
    }

    .up-price-table {
        td {
            border-color: $body-bg;
        }

        th {
            border: none;

            + th {
                border-radius: 5px 5px 0 0;
                padding: 20px 0;
            }
        }

        tbody {
            tr {
                &:first-child td {
                    border-top: none;
                }
            }
        }

        td,
        th {
            text-align: right;

            + td,
            + th {
                text-align: center;
            }

            + td {
                border-left: 1px solid $body-bg;
            }
        }
    }
}
// ======= Burgur menu start

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition: filter 0.15s linear;
    width: 20px;
    height: 20px;

    &.is-active {
        .hamburger-inner {
            background-color: #000;

            &::after,
            &::before {
                background-color: #000;
            }
        }
    }
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 18px;
    height: 2px;
    background-color: var(--pc-header-color);
    border-radius: 4px;
    position: absolute;
    transition: transform 0.15s ease;

    &::after,
    &::before {
        width: 18px;
        height: 2px;
        background-color: var(--pc-header-color);
        border-radius: 4px;
        position: absolute;
        transition: transform 0.15s ease;
    }

    &::after,
    &::before {
        content: "";
        display: block;
    }

    &::before {
        top: -6px;
    }

    &::after {
        bottom: -6px;
    }
}

.hamburger--arrowturn.is-active .hamburger-inner {
    transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(4px, 2px, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(4px, -2px, 0) rotate(-45deg) scale(0.7, 1);
}
// ======= Burgur menu End
@media (max-width: 1024px) {
    .pc-container {
        margin-left: 0;
        .page-header + .row,
        .page-header + .pcoded-content,
        .pcoded-content {
            padding-top: 30px;
        }
    }

    .page-header {
        position: relative;
        left: 0;
        top: 0;
    }

    .minimenu {
        .page-header {
            left: 0;
        }

        .pc-container {
            margin-left: 0;
        }
    }
}
@include media-breakpoint-down(sm) {
    .pc-container {
        .pcoded-content {
            padding: 20px;
        }

        .container {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .page-header {
        padding: 13px 20px;

        h5 {
            padding-bottom: 5px;
            margin-bottom: 0;
            border-right: none;
            font-size: 14px;
        }

        .breadcrumb {
            display: flex;
            margin-bottom: 0;
            font-size: 11px;
        }

        .text-md-right {
            margin-top: 5px;
        }
    }
}

.navbar-content {
    position: relative;
}

[data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
}

.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
}

.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
}

.simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.simplebar-content {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
}

.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
}

.simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}

.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
}

[data-simplebar].simplebar-dragging {
    .simplebar-content {
        pointer-events: none;
        user-select: none;
        -webkit-user-select: none;
    }

    .simplebar-track {
        pointer-events: all;
    }
}

.simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;

    &:before {
        position: absolute;
        content: "";
        background: darken($body-bg, 25%);
        border-radius: 7px;
        left: 2px;
        right: 2px;
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &.simplebar-visible:before {
        opacity: 0.5;
        transition: opacity 0s linear;
    }
}

.simplebar-track {
    &.simplebar-vertical {
        top: 0;
        width: 11px;

        .simplebar-scrollbar:before {
            top: 2px;
            bottom: 2px;
        }
    }

    &.simplebar-horizontal {
        left: 0;
        height: 11px;

        .simplebar-scrollbar {
            &:before {
                height: 100%;
                left: 2px;
                right: 2px;
            }

            right: auto;
            left: 0;
            top: 2px;
            height: 7px;
            min-height: 0;
            min-width: 10px;
            width: auto;
        }
    }
}

/* Rtl support */

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}

.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
}

.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
// Common layout css end
