// =======================================
//     List of variables for layout
// =======================================
:root {
    // body
    --#{$variable-prefix}body-bg: #{$body-bg};
    --bs-body-bg-rgb: #{to-rgb($body-bg)};
    --pc-heading-color: #{$gray-800};
  
    // Menu
    --pc-sidebar-background: #1c232f;
    --pc-sidebar-color: #ced4dc;
    --pc-sidebar-icon-color: #778290;
    --pc-sidebar-main-active-color: #fff;
    --pc-sidebar-main-active-background : #161c25;
    --pc-sidebar-active-color: var(--bs-primary);
    --pc-sidebar-caption: var(--bs-primary);
    --pc-sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    --pc-brand-color: #161c25;

    // header
    --pc-header-background: #fff;
    --pc-header-color: #525b69;
    --pc-header-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    --pc-breadcrumb-background: #fff;
    
    // horizontal topbar
    --pc-topbar-background: #1c232f;
    --pc-topbar-color: #b5bdca;
    --pc-header-submenu-background: #fff;
    --pc-header-submenu-color: #1c232f;

    // card
    --pc-card-shadow:  0 2px 6px -1px rgba(0, 0, 0, 0.1);
}
[data-pc-sidebar-theme="light"]{
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #1c232f;
  --pc-sidebar-icon-color: #525b69;
  --pc-sidebar-main-active-color: #1c232f;
  --pc-sidebar-main-active-background : #dfe7f1;
}

[data-pc-header-theme="dark"]{
  --pc-header-background: #1c232f;
  --pc-header-color : #fff;
  --pc-header-shadow: none;
}

$header-height: 70px;
$sidebar-width: 280px;
$sidebar-collapsed-width: 70px;

// horizontal menu
$topbar-height: 60px;


$soft-bg-level: -80%;
// =====================================
//      Variables for dark layouts
// =====================================
$dark-layout-color: #161c25;
// =====================================

$preset-colors: (
  preset-1: (
    primary: $blue-500
  ),
  preset-2: (
    primary: $indigo-500
  ),
  preset-3: (
    primary: $purple-500
  ),
  preset-4: (
    primary: $pink-500
  ),
  preset-5: (
    primary: $red-500
  ),
  preset-6: (
    primary: $orange-500
  ),
  preset-7: (
    primary: $yellow-500
  ),
  preset-8: (
    primary: $green-500
  ),
  preset-9: (
    primary: $teal-500
  ),
  preset-10: (
    primary: $cyan-500
  )
);

.modern-layout {
  --pc-header-background: #1c232f;
  --pc-header-color : #fff;
  --pc-header-shadow: none;

  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #1c232f;
  --pc-sidebar-icon-color: #525b69;
  --pc-sidebar-main-active-color: #1c232f;
  --pc-sidebar-main-active-background : #dfe7f1;
}

.advance-layout{
  --pc-header-background: transparent;
  --pc-header-color : #fff;
  --pc-header-shadow: none;

  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #1c232f;
  --pc-sidebar-icon-color: #525b69;
  --pc-sidebar-main-active-color: #1c232f;
  --pc-sidebar-main-active-background : #dfe7f1;
}

.tab-layout{
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #1c232f;
  --pc-sidebar-icon-color: #525b69;
  --pc-sidebar-main-active-color: #1c232f;
  --pc-sidebar-main-active-background : #dfe7f1; 
}

.creative-layout  {
  --pc-sidebar-background: #fff;
  --pc-sidebar-color: #1c232f;
  --pc-sidebar-icon-color: #525b69;
  --pc-sidebar-main-active-color: #1c232f;
  --pc-sidebar-main-active-background : #dfe7f1;
}
